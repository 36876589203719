import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import CompletedCTA from "../completed-cta";
import MarkAsCompleteCTA from "../mark-as-complete-cta";
import { APPLICATION_SECTION } from "src/constants/applications.constants";

const CompleteAutomation = ({
  t,
  application,
  ucasQuickSubmit,
  isUCASAccountCreated,
}) => {
  const isEmploymentFormComplete =
    ucasQuickSubmit?.personal_statement_form_complete;
  const isOnboardingInProgress =
    ucasQuickSubmit?.onboarding_show_create_button &&
    !ucasQuickSubmit?.onboarding_complete;
  const isJobRunning =
    ucasQuickSubmit?.personal_statement_form_job_status ===
      "Running" || ucasQuickSubmit?.jobs_running;

  if (isEmploymentFormComplete) {
    return (
      <CompletedCTA
        status={ucasQuickSubmit?.personal_statement_form_complete}
        type={APPLICATION_SECTION.PERSONAL_STATEMENT}
      />
    );
  } else if (isOnboardingInProgress || isJobRunning) {
    return (
      <>
        <button
          data-tip
          data-for={`tooltip`}
          disabled={true}
          className={"btn-border-primary mb-6"}
        >
          {t(
            "direct_apply_apps.quick_submit.account_and_onboarding.mark_as_complete_cta",
          )}
        </button>
        {!application.is_ucas_credential_updated &&
          isUCASAccountCreated && (
            <ReactTooltip
              id={`tooltip`}
              aria-haspopup="true"
              place="bottom"
              effect={"solid"}
              arrowColor={"transparent"}
            >
              <p>
                {t("direct_apply_apps.quick_submit.ucas_credential")}
              </p>
            </ReactTooltip>
          )}
      </>
    );
  } else {
    return (
      <MarkAsCompleteCTA
        status={ucasQuickSubmit?.personal_statement_form_complete}
        type={APPLICATION_SECTION.PERSONAL_STATEMENT}
      />
    );
  }
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  application: state.directApply.selected,
});

export default withTranslation()(
  connect(mapStateToProps)(CompleteAutomation),
);
