import { createReducer } from "reduxsauce";
import { UcasApplicationFormTypes as Types } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.ucasApplicationForm;

export const saveUcasApplicationFormFailure = (state, action) => ({
  ...state,
});
export const saveUcasApplicationFormRequest = (state, action) => ({
  ...state,
});
export const saveUcasApplicationFormSuccess = (state, action) => ({
  ...state,
});

export const fillEducationSectionFailure = (state, action) => ({
  ...state,
});
export const fillEducationSectionRequest = (state, action) => ({
  ...state,
});
export const fillEducationSectionSuccess = (state, action) => ({
  ...state,
});

export const fillPersonalStatementFailure = (state) => ({
  ...state,
});
export const fillPersonalStatementRequest = (state) => ({
  ...state,
});
export const fillPersonalStatementSuccess = (state) => ({
  ...state,
});

export const saveNotesFailure = (state) => ({
  ...state,
});
export const saveNotesRequest = (state) => ({
  ...state,
});
export const saveNotesSuccess = (state) => ({
  ...state,
});

export const getUcasApplicationFormFailure = (state) => ({
  ...state,
  loading: false,
  notes: "",
  reviewData: {},
});
export const getUcasApplicationFormRequest = (state) => ({
  ...state,
  loading: true,
});
export const getUcasApplicationFormSuccess = (state, action) => {
  const { applicationData } = action;
  console.log(applicationData);
  return {
    ...state,
    notes: applicationData.notes,
    reviewData: applicationData.review_data,
    syncDate: applicationData.sync_date,
    loading: false,
  };
};

export const requestIgnoreReviewDataFailure = (state) => ({ ...state });
export const requestIgnoreReviewData = (state) => ({ ...state });
export const requestIgnoreReviewDataSuccess = (state, action) => ({
  ...state,
  notes: action.info.notes,
  reviewData: action.info.review_data,
  syncDate: action.info.sync_date,
});

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.SAVE_UCAS_APPLICATION_FORM_FAILURE]: saveUcasApplicationFormFailure,
  [Types.SAVE_UCAS_APPLICATION_FORM_REQUEST]: saveUcasApplicationFormRequest,
  [Types.SAVE_UCAS_APPLICATION_FORM_SUCCESS]: saveUcasApplicationFormSuccess,

  [Types.FILL_EDUCATION_SECTION_FAILURE]: fillEducationSectionFailure,
  [Types.FILL_EDUCATION_SECTION_REQUEST]: fillEducationSectionRequest,
  [Types.FILL_EDUCATION_SECTION_SUCCESS]: fillEducationSectionSuccess,

  [Types.SAVE_NOTES_FAILURE]: saveNotesFailure,
  [Types.SAVE_NOTES_REQUEST]: saveNotesRequest,
  [Types.SAVE_NOTES_SUCCESS]: saveNotesSuccess,

  [Types.GET_UCAS_APPLICATION_FORM_FAILURE]: getUcasApplicationFormFailure,
  [Types.GET_UCAS_APPLICATION_FORM_REQUEST]: getUcasApplicationFormRequest,
  [Types.GET_UCAS_APPLICATION_FORM_SUCCESS]: getUcasApplicationFormSuccess,

  [Types.REQUEST_IGNORE_REVIEW_DATA_FAILURE]: requestIgnoreReviewDataFailure,
  [Types.REQUEST_IGNORE_REVIEW_DATA]: requestIgnoreReviewData,
  [Types.REQUEST_IGNORE_REVIEW_DATA_SUCCESS]: requestIgnoreReviewDataSuccess,

  [Types.FILL_PERSONAL_STATEMENT_FAILURE]: fillPersonalStatementFailure,
  [Types.FILL_PERSONAL_STATEMENT_REQUEST]: fillPersonalStatementRequest,
  [Types.FILL_PERSONAL_STATEMENT_SUCCESS]: fillPersonalStatementSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
