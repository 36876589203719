import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import UcasCredentialModal from "./ucas-credential-modal";
import { DirectApplyCreators } from "../../../../redux/actions";
import Header from "./header";
import Onboarding from "./onboarding-section/onboarding";
import FillForm from "./form-section/fill-form";
import Education from "./education-section/education";
import PersonalStatement from "./personal-statement-section/personal-statement";
import Choices from "./choices-section/choices";

const QuickSubmit = props => {
  const { application, ucasQuickSubmit } = props;

  const [expand, setExpand] = useState(false);
  const [isUcasModalVisible, setUcasModalVisibility] =
    useState(false);

  useEffect(() => {
    props.getUcasQuickSubmit(application?.id);
  }, []);

  useEffect(() => {
    if (ucasQuickSubmit?.status === "Incomplete") {
      setExpand(true);
    } else {
      setExpand(false);
    }
  }, [ucasQuickSubmit]);

  const onClickIsUcasCredentialUpdated = hasUcasCredentials => {
    const { user_id } = application;
    const data = {
      is_ucas_credential_updated: hasUcasCredentials
        ? hasUcasCredentials
        : false,
    };
    props.ucasCredentialUpdated(user_id, data);
  };

  return (
    <div className="my-5 mx-2">
      <Header expand={expand} setExpand={setExpand} />
      <div
        className={`flex justify-center h-auto bg-blue-50 display-section p-6 ${
          expand ? "rounded-t-none" : "hidden"
        }`}
      >
        <Onboarding
          isUCASAccountCreated={application?.using_own_ucas_account}
          openModal={() =>
            setUcasModalVisibility(!isUcasModalVisible)
          }
        />
        <FillForm
          isUCASAccountCreated={application?.using_own_ucas_account}
        />
        <Education
          isUCASAccountCreated={application?.using_own_ucas_account}
        />
        <PersonalStatement
          isUCASAccountCreated={application?.using_own_ucas_account}
        />
        <Choices
          isUCASAccountCreated={application?.using_own_ucas_account}
        />
      </div>
      <UcasCredentialModal
        visible={isUcasModalVisible}
        onClose={() => setUcasModalVisibility(!isUcasModalVisible)}
        application={application}
        onClickIsUcasCredentialUpdated={
          onClickIsUcasCredentialUpdated
        }
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ucasQuickSubmit: state.directApply.ucasQuickSubmit,
    isUcasAccountCreated: state.students.loading,
    application: state.directApply.selected,
  };
};

const mapDispatchToProps = {
  ucasCredentialUpdated:
    DirectApplyCreators.updatedUcasCredentialRequest,
  getUcasQuickSubmit: DirectApplyCreators.getUcasQuickSubmit,
  requestMarkComplete: DirectApplyCreators.requestMarkComplete,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuickSubmit);
