import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CreateAccountCTA from "./create-account-cta";
import UcasCTA from "./ucas-cta";
import CompleteAutomation from "./complete-automation";
import RenderCreationError from "./render-creation-error";
import ShowLastStatus from "../show-last-status";

const Onboarding = ({
  t,
  application,
  ucasQuickSubmit,
  isUCASAccountCreated,
  openModal,
}) => {
  const { onboarding_complete, onboarding_completed_at } =
    ucasQuickSubmit;

  const renderOnboardingMessage = () => {
    if (!onboarding_complete) {
      return (
        <span className="text-sm text-gray-500 pb-4">
          {application.is_ucas_credential_updated
            ? t(
                "direct_apply_apps.quick_submit.account_and_onboarding.fill_up_account",
              )
            : t(
                "direct_apply_apps.quick_submit.account_and_onboarding.create_account",
              )}
        </span>
      );
    }
    return null;
  };

  return (
    <div
      style={{ width: "20%" }}
      className="flex flex-col border rounded bg-white mx-2 relative"
    >
      <div className="border-b text-base font-bold p-4 text-center truncate">
        {t(
          "direct_apply_apps.quick_submit.account_and_onboarding.title",
        )}
      </div>
      <div className="flex flex-col items-center p-4 ">
        {renderOnboardingMessage()}
        <CreateAccountCTA
          isUCASAccountAvailable={isUCASAccountCreated}
        />
        <UcasCTA
          isUCASAccountCreated={isUCASAccountCreated}
          openModal={openModal}
        />
        <CompleteAutomation
          isUCASAccountCreated={isUCASAccountCreated}
        />
        <RenderCreationError />
        <div className="mt-6" />
        <ShowLastStatus time={onboarding_completed_at} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  application: state.directApply.selected,
});

export default withTranslation()(
  connect(mapStateToProps)(Onboarding),
);
