import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import Loader from "react-loader-spinner";

import { UcasApplicationFormCreators } from "../../../../../redux/actions";

const PENDING = "Running";
const RUNNING = "Pending";

const FillFormCTA = ({
  t,
  application,
  ucasQuickSubmit,
  isUCASAccountCreated,
  saveUcasApplicationForm,
  taggingQuickSubmit,
}) => {
  const {
    application_form_job_status,
    onboarding_show_create_button,
    onboarding_complete,
    jobs_running,
  } = ucasQuickSubmit;

  const isFormFailed = application_form_job_status === "Failed";

  const handleSaveForm = () => {
    saveUcasApplicationForm(application?.id);
  };

  if (ucasQuickSubmit?.application_form_complete) return null;

  const renderButton = () => {
    switch (true) {
      case application_form_job_status === PENDING:
      case application_form_job_status === RUNNING:
        return (
          <div data-tip data-for="fill_application_form_button">
            <button
              className="button-contain-loading-primary-disable"
              disabled
            >
              Fill form
              <Loader
                className="ml-2 text-gray-500"
                type="Oval"
                height={10}
                width={10}
              />
            </button>
            <ReactTooltip
              id="fill_application_form_button"
              aria-haspopup="true"
              place="bottom"
              effect="solid"
              arrowColor="transparent"
            >
              <p>Form Filling is in progress</p>
            </ReactTooltip>
          </div>
        );
      case (onboarding_show_create_button && !onboarding_complete) ||
        jobs_running ||
        taggingQuickSubmit?.jobs_running ||
        (!application.is_ucas_credential_updated &&
          isUCASAccountCreated):
        return (
          <>
            <button
              data-tip
              data-for="tooltip-Complete"
              disabled
              className="btn-border-primary mb-6"
            >
              Fill form
              <FontAwesomeIcon
                icon={["far", "sparkles"]}
                className="pl-2"
              />
            </button>
            {!application.is_ucas_credential_updated &&
              isUCASAccountCreated && (
                <ReactTooltip
                  id="tooltip-Complete"
                  aria-haspopup="true"
                  place="bottom"
                  effect="solid"
                  arrowColor="transparent"
                >
                  <p>
                    {t(
                      "direct_apply_apps.quick_submit.ucas_credential",
                    )}
                  </p>
                </ReactTooltip>
              )}
          </>
        );
      case !application?.in_cialfo_review:
        return (
          <>
            <button
              data-tip
              data-for="hide-cta"
              disabled
              className="btn-border-primary mb-6"
            >
              Fill form
              <FontAwesomeIcon
                icon={["far", "sparkles"]}
                className="pl-2"
              />
            </button>
            <ReactTooltip
              id="hide-cta"
              aria-haspopup="true"
              place="bottom"
              effect="solid"
              arrowColor="transparent"
            >
              {renderTooltipContent()}
            </ReactTooltip>
          </>
        );
      case isFormFailed:
        return (
          <div>
            <div className="flex justify-center">
              <button
                onClick={handleSaveForm}
                className="button-contain-error mb-2"
              >
                Try again
                <FontAwesomeIcon icon="undo" className="pl-2" />
              </button>
            </div>
            <span className="text-base text-red-800 mt-2 block text-center mb-6">
              {application.ucas_form_filling_error_message ||
                "An error occurred. Please try again. You may also fill this manually or reach out to support."}
            </span>
          </div>
        );
      default:
        return (
          <button
            onClick={handleSaveForm}
            className="button-contain-primary"
          >
            Fill form
            <FontAwesomeIcon
              icon={["far", "sparkles"]}
              className="pl-2"
            />
          </button>
        );
    }
  };

  const renderTooltipContent = () => {
    return (
      <p>
        {t("direct_apply_apps.quick_submit.disable_automation_toast")}
      </p>
    );
  };

  return <div className="mb-6">{renderButton()}</div>;
};

function mapStateToProps(state) {
  return {
    taggingQuickSubmit: state.directApply.taggingQuickSubmit,
    ucasQuickSubmit: state.directApply.ucasQuickSubmit,
    application: state.directApply.selected,
  };
}

const mapDispatchToProps = {
  saveUcasApplicationForm:
    UcasApplicationFormCreators.saveUcasApplicationFormRequest,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(FillFormCTA),
);
