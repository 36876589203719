import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import FillChoicesCTA from "./fill-choices-cta";
import CompleteAutomation from "./complete-automation";
import ShowLastStatus from "../show-last-status";

const Choices = ({ t, isUCASAccountCreated, ucasQuickSubmit }) => {
  const { choices_form_complete, choices_form_completed_at } =
    ucasQuickSubmit;

  return (
    <div
      style={{ width: "20%" }}
      className="flex flex-col border rounded bg-white mx-2 relative"
    >
      <div className="border-b text-base font-bold p-4 text-center truncate">
        {t("direct_apply_apps.quick_submit.choices.title")}
      </div>
      <div className="flex flex-col items-center p-4">
        <span className="text-sm text-gray-500 pb-4 text-center">
          {t("direct_apply_apps.quick_submit.choices.subTitle")}
        </span>
        <CompleteAutomation
          isUCASAccountCreated={isUCASAccountCreated}
        />
        <FillChoicesCTA isUCASAccountCreated={isUCASAccountCreated} />
        {!choices_form_complete && (
          <span className="text-sm text-gray-500 my-4 mb-8 text-center">
            {t(
              "direct_apply_apps.quick_submit.choices.fill_choices_detail",
            )}
          </span>
        )}
        <ShowLastStatus time={choices_form_completed_at} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
});

export default withTranslation()(connect(mapStateToProps)(Choices));
