import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ShowLastStatus from "../show-last-status";
import CompleteAutomation from "./complete-automation";
import PersonalStatementCta from "./personal-statement-cta";

const PersonalStatement = ({
  t,
  ucasQuickSubmit,
  isUCASAccountCreated,
  application,
}) => {
  return (
    <div
      style={{ width: "20%" }}
      className="flex flex-col border rounded bg-white mx-2 relative"
    >
      <div className="border-b text-base font-bold p-4 text-center truncate">
        {t("direct_apply_apps.quick_submit.employment.title")}
      </div>
      <div className="flex flex-col items-center p-4">
        <span className="text-sm text-gray-500 pb-4 text-center">
          {t("direct_apply_apps.quick_submit.employment.subTitle")}
        </span>
        <CompleteAutomation
          isUCASAccountCreated={isUCASAccountCreated}
        />
        {!ucasQuickSubmit?.personal_statement_form_complete && (
          <PersonalStatementCta
            application={application}
            ucasQuickSubmit={ucasQuickSubmit}
            isUCASAccountCreated={isUCASAccountCreated}
          />
        )}
        <div className="mt-8" />
        <ShowLastStatus
          time={ucasQuickSubmit?.personal_statement_form_completed_at}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ucasQuickSubmit: state.directApply.ucasQuickSubmit,
  application: state.directApply.selected,
});

export default withTranslation()(
  connect(mapStateToProps)(PersonalStatement),
);
